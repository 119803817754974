<template>
  <div>
    <div>
      <VueSlickCarousel v-bind="slickOptions">
        <div
          v-for="(content, i) in messageItem.messageResponse.content.content
            .flexReply"
          :key="i"
          class="msg_wrap"
        >
          <div class="wrap-product-img">
            <div
              class="product-img"
              :title="messageItem.messageResponse.content.content.altText"
              v-bind:style="{
                backgroundImage: `url(&quot;${content.ImageUrl}&quot;)`,
              }"
            ></div>
          </div>
          <div class="container-text">
            <span v-html="content.Text" class="msg_wrap"
              >{{ content.Text }}
            </span>
          </div>
          <div></div>
          <div
            v-for="(btn, j) in content.Button"
            :key="j"
            class="container-btn"
          >
            <button
              v-if="btn.Action != 8"
              type="button"
              class="list-btn"
              v-bind:style="{ backgroundColor: themeColor }"
              @click="
                () => {
                  handleClickButton(btn.Action, btn.Label, btn.Data);
                }
              "
            >
              {{ btn.Label }}
            </button>
            <a
              v-else
              class="list-btn btn-tel"
              v-bind:style="{ backgroundColor: themeColor }"
              :href="`tel:${btn.Label}`"
            >
              {{ btn.Label }}
            </a>
          </div>
        </div>
        <template #prevArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
        <template #nextArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "ChatBoxFlexCarousel",
  components: {
    VueSlickCarousel,
  },
  props: {
    config: {
      required: true,
      type: Object,
    },
    messageItem: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      themeColor: this.config.color,
      slickOptions: {
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        dots: false,
        initialSlide: 0,
        autoplay: false,
      },
    };
  },
  methods: {
    handleClickButton(btnAction, btnLabel, btnData) {
      if (btnAction == 1) {
        this.$emit("send-message", { text: btnData }, 1);
      } else if (btnAction == 2) {
        this.handleOpenTab(btnData);
      } else if (btnAction == 3) {
        // post back
        this.$emit("send-message", { text: btnData }, 2, btnLabel);
      } else if (btnAction == 6) {
        this.openMap();
      }
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    openMap() {
      this.$emit("setShowMap", true);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: inherit;
}
.msg_wrap {
  overflow-wrap: break-word;
  color: #595a5c;
  font-size: 16px;
}
.wrap-product-img {
  width: 100%;
  height: auto;
}
.product-img {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.msg-card-sub-title {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  color: #a0a0a0;
  display: flex;
  justify-content: center;
}
.slick-prev:before,
.slick-next:before {
  color: rgba(22, 39, 74, 0.4);
  font-size: 20px;
}
.slick-next {
  margin-right: 5px;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.container-text {
  margin-top: 8px;
}
.container-btn {
  padding-top: 8px;
  display: flex;
  flex-flow: column;
  margin-top: auto;
}
.list-btn {
  border-radius: 0.5rem;
  color: white;
  border: 0px;
  padding: 0.4rem;
  font-size: 16px;
  font-family: inherit;
}
.list-btn:hover {
  cursor: pointer;
}
.btn-tel {
  text-decoration-line: none;
  text-align: center;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89, 90, 92, 0.25);
  outline: none;
}
</style>
