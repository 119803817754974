import {
  required,
  email,
  minLength,
  helpers,
  integer,
  maxLength,
} from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);

export default {
  preChatForm: {
    formTitle: "รบกวนกรอกข้อมูลด้านล่าง",
    formTitleEn: "Register live chat",
    field: {
      firstName: {
        label: "ชื่อ",
        labelEn: "First Name",
        fieldName: "firstName",
        type: "text",
        value: "",
        required: true,
      },
      lastName: {
        label: "นามสกุล",
        labelEn: "Last Name",
        fieldName: "lastName",
        type: "text",
        value: "",
        required: true,
      },
      email: {
        label: "อีเมล",
        labelEn: "Email",
        fieldName: "email",
        type: "email",
        value: "",
        required: true,
      },
      numberPhone: {
        label: "หมายเลขโทรศัพท์",
        labelEn: "Phone",
        fieldName: "numberPhone",
        type: "text",
        value: "",
        required: true,
      },
    },
    buttonTitle: "",
    validate: {
      firstName: { required, alpha },
      lastName: { required, alpha },
      email: { required, email },
      numberPhone: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(10),
        integer,
      },
    },
  },
  //
  // chatBaseUrl: `${process.env.VUE_APP_BASE_URL}`,
  // apiKey: `${process.env.VUE_APP_API_KEY}`,
  // chatBaseUrlSocket: `${process.env.VUE_APP_BASE_URL_SOCKET}`,
  apiKeyGoogleMap: `AIzaSyBM54I4QIZFuxiYr2W-t980Wp8cAiOx7O8`,
  // apiKeyGoogleMap: `${process.env.VUE_APP_API_KEY_GOOGLE_MAP}`,
  // socketKey: `${process.env.VUE_APP_SOCKET_KEY}`,

  // channelReferenceId: "WEB_D_BOT_4",

  color: "#b41bb4",
  secondaryColor: "#fde2d3",
  titleButton: "Livechat",
  title: "Live Chat",
  // brandName: "Dbot",
  // brandId: 0,
  platformName: "Web",
  platformId: 2,
  userId: "",
  agentIconUrl: "",
  agentName: "",
  userIcon: "",
};
