<template>
  <div class="form">
    <div>
      <div class="alert" v-if="isError">
        <font-awesome-icon icon="exclamation-circle" />
        ไม่สำเร็จ
      </div>
    </div>
    <form v-on:submit.prevent="sendForm">
      <div v-for="(item, name) in field" :key="name">
        <InputText
          :config="config"
          :value="item.value"
          :textFloat="cookieLang == 'th' ? item.label : item.labelEn"
          @onDataChange="
            (val) => {
              $v.form[name].$model = val;
              field[name].value = val;
            }
          "
          :placeholder="cookieLang == 'th' ? item.label : item.labelEn"
          :name="item.fieldName"
          :type="item.type"
          :isRequired="item.required"
          :isValidate="$v.form[name].$error"
          :v="$v.form[name]"
        />
      </div>

      <div v-if="buttonTitle" class="container-btn">
        <button
          type="submit"
          class="btn-chat"
          v-bind:style="[{ backgroundColor: primaryColor }]"
        >
          {{ buttonTitle }}
        </button>
      </div>

      <div v-else class="container-btn-icon">
        <div v-show="isLoading" class="container-loader">
          <div class="snippet" data-title="dot-carousel">
            <div class="stage">
              <div class="dot-carousel"></div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          v-bind:style="[{ color: primaryColor, border: 0 }]"
          class="btn-icon-chat"
          :disabled="isLoading"
        >
          <font-awesome-icon icon="arrow-circle-right" id="iconChat" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import InputText from "../input/InputText.vue";

export default {
  name: "ChatForm",
  props: {
    config: Object,
  },
  components: {
    InputText,
  },
  mounted() {
    this.cookieLang = this.$cookies.isKey("d-lang")
      ? this.$cookies.get("d-lang")
      : "th";
    document.getElementById("iconChat").style.fontSize = 25 + "px";
    document.getElementsByClassName("dot-carousel")[0].style.color =
      this.config.color || "#9880ff";
    document.getElementsByClassName("dot-carousel")[0].style.backgroundColor =
      this.config.color || "#9880ff";
  },
  data() {
    return {
      buttonTitle: this.config.preChatForm.buttonTitle,
      field: this.config.preChatForm.field,
      form: {
        brandId: this.config.brandId,
        platformId: this.config.platformId,
        channelReferenceId: this.config.channelReferenceId,
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        numberPhone: "",
      },
      isError: false,
      primaryColor: this.config.color,
      isLoading: false,
      cookieLang: "",
    };
  },
  created() {
    if (this.$cookies.get("d-user-info")) {
      // go to chat page (ChatBox)
      this.$emit("setShowChatBox", true);
    }
  },
  validations() {
    return {
      form: this.config.preChatForm.validate,
    };
  },
  methods: {
    sendForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.$v.form.$reset();

      // api register
      this.register();
    },
    async register() {
      this.isLoading = true;
      var webId = "";
      await this.$axios({
        method: "get",
        url: this.config.apiPath + "/web/setting/" + this.form.brandId + "/webId/" + this.config.channelReferenceId,
        headers: {
          "API-KEY": this.config.apiKey,
          "Content-Type": "application/json",
        },
      }).then((result) => {
        if (result.data.result == 1) {
          webId = result.data.detail.webId;
        } else {
          // pop up error
          this.handleLoadError();
        }
      });

      var data = {
        brandId: this.form.brandId,
        platformId: this.form.platformId,
        channelReferenceId: this.config.channelReferenceId,
        firstname: this.form.firstName,
        lastname: this.form.lastName,
        telephone: this.form.numberPhone,
        email: this.form.email,
      };

      this.$axios({
        method: "post",
        url: this.config.apiPath + "/user/register",
        data: data,
        headers: {
          "API-KEY": this.config.apiKey,
          "Content-Type": "application/json",
        },
      }).then((result) => {
        if (result.data.result == 1) {
          this.isError = false;

          this.form.userId = result.data.detail.userGUID;

          // set userId in config file
          this.config.userId = result.data.detail.userGUID;

          // set cookies
          this.$cookies.set("d-user-info", this.form);

          this.isLoading = false;

          // go to chat page (ChatBox)
          this.$emit("setShowChatBox", true);
        } else {
          // pop up error
          this.handleLoadError();
        }
      });
    },
    handleLoadError() {
      this.isError = true;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 0.7rem 1rem 0.7rem 1rem;
}
.btn-chat {
  border: none;
  border-radius: 0.5rem;
  padding: 7px;
  color: white;
  font-size: 15px;
}
.btn-chat:hover {
  cursor: pointer;
}
.container-btn {
  display: grid;
}
.container-btn-icon {
  display: flex;
  justify-content: flex-end;
}
.btn-icon-chat {
  background-color: inherit;
}
.btn-icon-chat svg {
  height: 1.4rem !important;
  width: auto;
}
.btn-icon-chat:hover {
  cursor: pointer;
}
.alert {
  margin-bottom: 8px;
  color: red;
}
.container-loader {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
}

button:focus {
  box-shadow: none;
  outline: none;
}

// loading

.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  // background-color: #9880ff;
  // color: #9880ff;
  box-shadow: 9984px 0 0 0, 9999px 0 0 0, 10014px 0 0 0;
  animation: dot-carousel 1.5s infinite linear;
}

@keyframes dot-carousel {
  0% {
    box-shadow: 9984px 0 0 -1px, 9999px 0 0 1px, 10014px 0 0 -1px;
  }
  50% {
    box-shadow: 10014px 0 0 -1px, 9984px 0 0 -1px, 9999px 0 0 1px;
  }
  100% {
    box-shadow: 9999px 0 0 1px, 10014px 0 0 -1px, 9984px 0 0 -1px;
  }
}

@media (max-width: 767.98px) {
  .form {
    height: 100vh;
  }
}
</style>
