var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-frame"},[_c('div',{staticClass:"container-item"},[_c('div',{staticClass:"lower-row display-line"},[_c('div',{staticClass:"icon-section"},[_c('div',{staticClass:"img-icon-display",style:({
              backgroundImage:
                'url(' +
                _vm.messageItem.messageResponse.content.message.IconTracking +
                ')',
            })})]),_c('div',{staticClass:"info-section"},[_c('span',{staticClass:"info",domProps:{"innerHTML":_vm._s(_vm.messageItem.messageResponse.content.message.MyTracking)}},[_vm._v(" "+_vm._s(_vm.messageItem.messageResponse.content.message.MyTracking)+" ")])])]),_c('div',{staticClass:"lower-row display-line"},[_c('div',{staticClass:"icon-section"},[_c('div',{staticClass:"img-icon-display",style:({
              backgroundImage:
                'url(' +
                _vm.messageItem.messageResponse.content.message.IconAddress +
                ')',
            })})]),_c('div',{staticClass:"info-section"},[_c('span',{staticClass:"info",domProps:{"innerHTML":_vm._s(_vm.messageItem.messageResponse.content.message.Address)}},[_vm._v(" "+_vm._s(_vm.messageItem.messageResponse.content.message.Address))])])])]),_c('hr'),_c('div',{staticClass:"container-item"},[_c('div',{staticClass:"wrap-product-img"},[_c('div',{staticClass:"product-img",style:({
            backgroundImage:
              'url(' +
              _vm.messageItem.messageResponse.content.message.QRCodeUrl +
              ')',
          })})]),_c('div',{staticClass:"booking-no"},[_vm._v(" "+_vm._s(_vm.messageItem.messageResponse.content.message.FooterTracking)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }