<template>
  <div>
    <div class="container-frame">
      <div class="container-text-title-top container-item">
        <span
          v-html="
            messageItem.messageResponse.content.location.Location.BranchName
          "
          class="msg-card-title title-black"
          >{{
            messageItem.messageResponse.content.location.Location.BranchName
          }}</span
        >
      </div>
      <hr />

      <div class="container-item">
        <div class="lower-row display-line">
          <div class="icon-section">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.location.Location
                    .IconBranch +
                  ')',
              }"
            ></div>
          </div>
          <div class="info-section">
            <span
              v-html="
                messageItem.messageResponse.content.location.Location.Address
              "
              class="info"
              >{{
                messageItem.messageResponse.content.location.Location.Address
              }}</span
            >
          </div>
        </div>

        <div class="lower-row display-line">
          <div class="icon-section">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.location.Location
                    .IconOpenHour +
                  ')',
              }"
            ></div>
          </div>
          <div class="info-section">
            <span
              v-html="
                messageItem.messageResponse.content.location.Location.OpenHour
              "
              class="info"
              >{{
                messageItem.messageResponse.content.location.Location.OpenHour
              }}</span
            >
          </div>
        </div>

        <div class="lower-row display-line">
          <div class="icon-section">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.location.Location
                    .IconCutOffTime +
                  ')',
              }"
            ></div>
          </div>
          <div class="info-section">
            <span
              v-html="
                messageItem.messageResponse.content.location.Location.CutOffTime
              "
              class="info"
              >{{
                messageItem.messageResponse.content.location.Location.CutOffTime
              }}</span
            >
          </div>
        </div>

        <div class="lower-row display-line">
          <div class="icon-section">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.location.Location
                    .IconTel +
                  ')',
              }"
            ></div>
          </div>
          <div class="info-section">
            <a
              class="info text-tel"
              :href="`tel:${messageItem.messageResponse.content.location.Location.Tel}`"
              >{{
                messageItem.messageResponse.content.location.Location.Tel
              }}</a
            >
          </div>
        </div>

        <div class="lower-row display-line">
          <div class="icon-section">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.location.Location
                    .ServiceIcon +
                  ')',
              }"
            ></div>
          </div>

          <div class="info-section">
            <span v-html="strService" class="info">{{ strService }}</span>
          </div>
        </div>
      </div>

      <hr />
      <div class="column container-item">
        <button
          type="button"
          class="list-btn"
          v-bind:style="{ backgroundColor: primaryColor }"
          @click="
            () => {
              handleOpenTab(
                messageItem.messageResponse.content.location.Location.BranchUrl
              );
            }
          "
        >
          {{ messageItem.messageResponse.content.location.ButtonLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatBoxLocationDetail",
  props: {
    config: {
      required: true,
      type: Object,
    },
    messageItem: {
      required: false,
      type: Object,
    },
  },
  mounted() {
    var str = "";
    for (let val of this.messageItem.messageResponse.content.location.Location
      .AvailableServices) {
      str += val + ", ";
    }
    this.strService = str.slice(0, -2);
  },
  data() {
    return {
      primaryColor: this.config.color,
      secondaryColor: this.config.secondaryColor,
      strService: "",
    };
  },
  methods: {
    handleClickButton(btnAction, btnLabel, btnData) {
      if (btnAction == 1) {
        this.$emit("send-message", { text: btnData }, 1);
      } else if (btnAction == 2) {
        this.handleOpenTab(btnData);
      } else if (btnAction == 3) {
        // post back
        this.$emit("send-message", { text: btnData }, 2, btnLabel);
      } else if (btnAction == 6) {
        this.openMap();
      }
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    openMap() {
      this.$emit("setShowMap", true);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: inherit;
}
.msg-card-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  display: flex;
  justify-content: center;
  font-size: 1rem;
}
.container-frame {
  padding-top: 10px;
  padding-bottom: 5px;
}
.container-item {
  padding-left: 12px;
  padding-right: 12px;
}
.list-btn {
  border-radius: 0.5rem;
  margin-bottom: 5px;
  color: white;
  border: 0px;
  padding: 0.4rem;
  font-size: 16px;
  font-family: inherit;
}
.list-btn:hover {
  cursor: pointer;
}
.column {
  display: flex;
  flex-flow: column;
}
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #e0e0e0;
  margin-top: 8px;
  margin-bottom: 10px;
}
.container-text-title-top {
  margin-top: 0px;
}
.title-black {
  color: #3a3937;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.lower-row {
  font-size: 14px;
}
.display-line {
  display: inline-flex;
  width: 100%;
  align-items: flex-start;
}
.icon-section {
  width: 10%;
  justify-content: center;
  display: flex;
  padding-top: 2px;
}
.img-icon-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 17px;
  width: 13px;
}
.info {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  color: #595a5c;
  display: block;
}
.info-section {
  width: 90%;
  display: flex;
}
.text-tel {
  text-decoration: underline;
  color: #007bff;
}
.text-tel:hover {
  cursor: pointer;
}
.container-service {
  padding: 5px 2px;
  display: inline;
}
.item-service {
  overflow-wrap: break-word;
  background-color: bisque;
  margin: 1px 0px;
  padding: 0.4rem;
  border: 0;
  font-family: inherit;
  font-size: 14px;
}
.item-service:hover {
  cursor: pointer;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89, 90, 92, 0.25);
  outline: none;
}

@media (max-width: 767.98px) {
  .icon-section {
    width: 5%;
    justify-content: center;
    display: flex;
  }
  .info-section {
    width: 95%;
    margin-left: 8px;
  }
}
</style>
