var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.messageItem.messageResponse.content.responseType == 3)?_c('div',{staticClass:"container-frame"},[_c('VueSlickCarousel',_vm._b({scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_vm._v(" "+_vm._s(arrowOption.currentSlide)+"/"+_vm._s(arrowOption.slideCount)+" ")])]}},{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_vm._v(" "+_vm._s(arrowOption.currentSlide)+"/"+_vm._s(arrowOption.slideCount)+" ")])]}}],null,false,393939391)},'VueSlickCarousel',_vm.slickOptions,false),_vm._l((_vm.messageItem.messageResponse.content.imageList),function(content,i){return _c('div',{key:i,staticClass:"msg_wrap"},[_c('div',{staticClass:"wrap-product-img"},[_c('div',{staticClass:"product-img",style:({
              backgroundImage: ("url(\"" + content + "\")"),
            })})]),_c('div',{staticClass:"container-item"},[_c('span',{staticClass:"msg-card-sub-title",domProps:{"innerHTML":_vm._s(_vm.messageItem.messageResponse.content.content.text)}},[_vm._v(" "+_vm._s(_vm.messageItem.messageResponse.content.content.text)+" ")])]),_c('div',{staticClass:"container-btn container-item"},_vm._l((_vm.messageItem.messageResponse.content.content
              .buttons),function(btn,j){return _c('button',{key:j,staticClass:"list-btn",style:({ backgroundColor: _vm.primaryColor }),attrs:{"type":"button"},on:{"click":function () {
                _vm.handleClickButton(btn.Action, btn.Label, btn.Data);
              }}},[_vm._v(" "+_vm._s(btn.Label)+" ")])}),0)])}),0)],1):_c('div',{staticClass:"container-frame"},[_c('VueSlickCarousel',_vm._b({scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_vm._v(" "+_vm._s(arrowOption.currentSlide)+"/"+_vm._s(arrowOption.slideCount)+" ")])]}},{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_vm._v(" "+_vm._s(arrowOption.currentSlide)+"/"+_vm._s(arrowOption.slideCount)+" ")])]}}])},'VueSlickCarousel',_vm.slickOptions,false),_vm._l((_vm.messageItem.messageResponse.content.imageList),function(content,i){return _c('div',{key:i,staticClass:"msg_wrap"},[_c('div',{staticClass:"wrap-product-img"},[_c('div',{staticClass:"product-img",style:({
              backgroundImage: ("url(\"" + content + "\")"),
            })})]),_c('div',{staticClass:"container-item"},[_c('span',{staticClass:"msg-card-sub-title",domProps:{"innerHTML":_vm._s(_vm.messageItem.messageResponse.content.content.text)}},[_vm._v(" "+_vm._s(_vm.messageItem.messageResponse.content.content.text)+" ")])]),_c('hr'),_vm._l((_vm.messageItem.messageResponse.content.content
            .buttons),function(btn,j){return _c('div',{key:j,staticClass:"container-btn container-item"},[(btn.Action != 8)?_c('button',{staticClass:"list-btn",style:({ backgroundColor: _vm.primaryColor }),attrs:{"type":"button"},on:{"click":function () {
                _vm.handleClickButton(btn.Action, btn.Label, btn.Data);
              }}},[_vm._v(" "+_vm._s(btn.Label)+" ")]):_c('a',{staticClass:"list-btn btn-tel",style:({ backgroundColor: _vm.themeColor }),attrs:{"href":("tel:" + (btn.Label))}},[_vm._v(" "+_vm._s(btn.Label)+" ")])])})],2)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }